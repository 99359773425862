import {useStyles} from '@wix/tpa-settings/react'
import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments} from '@wix/yoshi-flow-editor'
import React, {useEffect, useReducer, useRef} from 'react'
import {DynamicStyle} from '../../../../../commons/components/dynamic-style'
import {isEditor} from '../../../../../commons/selectors/environment'
import {useSettings} from '../../../hooks/use-settings'
import settingsParams from '../../../settingsParams'
import stylesParams from '../../../stylesParams'
import {useWidgetState} from '../../hooks/state-provider'
import {useEventsList} from '../../hooks/use-events-list'
import {LoadMoreButton} from '../load-more-button'
import {MainListTitle} from '../main-list-title'
import {Card} from './card'
import s from './cards.scss'
import {CardsProps} from '.'

export const Cards = ({t}: CardsProps) => {
  const container = useRef<HTMLDivElement>()
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const {events, hasMore, moreLoading} = useEventsList()
  const {experiments} = useExperiments()
  const loadMoreButtonImprovementsEnabled = experiments.enabled(ExperimentNames.LoadMoreButtonImprovements)
  const settings = useSettings()
  const editor = useWidgetState(isEditor)

  const styles = useStyles()

  const cardsPerRow = styles.get(stylesParams.cardsPerRow)
  const limit = settings.get(settingsParams.eventsPerPage)
  const eventsCount = events.length

  const columnCount = Math.min(cardsPerRow, eventsCount, limit)

  useEffect(() => {
    const onResize = () => {
      forceUpdate()
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <div className={s.background}>
      {editor && loadMoreButtonImprovementsEnabled && (
        <DynamicStyle>
          {`.${s.container} {
            --columns: ${columnCount};
          }`}
        </DynamicStyle>
      )}
      <div ref={container} className={s.container}>
        <MainListTitle />
        <ul className={s.cards} data-hook={DH.cards}>
          {events.map(event => (
            <Card event={event} t={t} key={event.id} />
          ))}
        </ul>
        {loadMoreButtonImprovementsEnabled ? (
          <div className={s.loadMoreWrapper}>
            <LoadMoreButton hasMore={hasMore} moreLoading={moreLoading} />
          </div>
        ) : (
          <LoadMoreButton hasMore={hasMore} moreLoading={moreLoading} />
        )}
      </div>
    </div>
  )
}
